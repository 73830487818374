import React from 'react';
import ReactDOM from 'react-dom';
import ScriptLoader from 'react-script-loader-hoc';
import { ai } from '../TelemetryService';
import Data from '../Data.js';
import * as Components from '../Components.js';
import './Pay.scss';

class Pay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      dataLoading: true,
      dataInitialLoad: false,
      paymentError: false,
      paymentLoading: false
    }
  }

  async componentWillMount() {
    if (!this.state.dataInitialLoad) {
      await this.refreshData();
    }
  }

  async refreshData() {
    var state = {
      paymentError: false
    };

    try {
      state.data = await Data.getBooking(this.props.match.params.id);
    } catch (e) {
    } finally {
      state.dataLoading = false;
      state.dataInitialLoad = true;
    }

    this.setState(state);
  }

  createOrder (data, actions) {
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: this.state.data.outstandingBalance
        }
      }]
    });
  }

  onApprove (data, actions) {
    return actions.order.capture().then((details) => {
      Data.submitPayment({
        bookingId: this.state.data.booking.rowKey,
        payerEmail: details.payer.email_address,
        payerId: details.payer.payer_id,
        payerName: details.payer.name.given_name + details.payer.name.surname,
        amount: details.purchase_units[0].amount.value,
        status: details.status
      }).then((response) => {
        this.setState({
          data: null,
          dataLoading: true
        });

        this.refreshData();
      })
    }).catch((e) => {
      this.setState({
        paymentError: true
      })
    });
  }

  wrap (content) {
    return <article>{content}</article>;
  }

  render () {
    var loading = 
      !this.props.scriptsLoaded || 
      this.state.dataLoading ||
      this.state.paymentLoading;

    if (loading) {
      return this.wrap(<PayLoading />);
    }

    if (this.state.paymentError) {
      return this.wrap(
        <PaypalError onBack={() => this.refreshData(true)} />
      );
    }

    if (this.state.data === null) {
      return this.wrap(
        <PaymentNotFound />
      );
    }

    return this.wrap(
      <PayContent 
        {...this.props}
        data={this.state.data}
        onCreateOrder={(data, actions) => this.createOrder(data, actions)}
        onApprove={(data, actions) => this.onApprove(data, actions)} />
    );
  }
};

export default ScriptLoader('https://www.paypal.com/sdk/js?currency=GBP&client-id=' + Data.paypalClientId())(ai.withAITracking(Pay));

const PayLoading = () => (
  <>
  <Components.TextBlock heading="Loading booking" />
  <Components.Loader />
  </>
);

const PayContent = (props) => {
  const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

  return (
    <Components.TextBlock heading="Booking details">
      <p></p>
      <p>
        Below are the details of you booking. If any of these details are wrong, or you would like to change them, place contact us.
      </p>
      <p>
        To pay the remaining balance of your holiday, please click the PayPal button below.
      </p>
      <div className="pay-details">
        <ul>
          <li className="heading">Booking details</li>
          <li><span>Last name: </span>{props.data.booking.lastName}</li>
          <li><span>Email: </span>{props.data.booking.email}</li>
          <li><span>Address: </span>{props.data.booking.addressLineOne}</li>
          <li><span>Date: </span>{props.data.booking.date}</li>
          <li><span>Price: </span>£{props.data.booking.price}</li>
        </ul>

        <ul>
          <li className="heading">Payments</li>
          {props.data.payments.length > 0 && (props.data.payments.map((p, i) => (
            <li key={i}><span>{p.formattedDate}: </span>&pound;{p.amount}</li>
          )))}
        </ul>

        <ul>
          <li className="heading">Balance</li>
          <li><span>To pay: </span>&pound;{props.data.outstandingBalance}</li>
          {props.data.outstandingBalance > 0 && (
            <>
            <li className="heading">Pay balance</li>
            <li>
              <PayPalButton
                createOrder={(data, actions) => props.onCreateOrder(data, actions)}
                onApprove={(data, actions) => props.onApprove(data, actions)} />
            </li>
            </>
          )}
        </ul>
      </div>
    </Components.TextBlock>
  )
};

const PaymentNotFound = props => (
  <Components.TextBlock heading="Booking not found">
    <p>Please contact us if there are any problems</p>
  </Components.TextBlock>
);

const PaypalError = props => {
  return (
    <Components.TextBlock heading="Payment failed">
      <p>Something went wrong ...</p>
      <button onClick={props.onBack}>Go back</button>
    </Components.TextBlock>
  );
};
