import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import ScriptLoader from 'react-script-loader-hoc';
import { ai } from '../TelemetryService';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { ErrorMessage } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import InstagramEmbed from 'react-instagram-embed';
import Data from '../Data.js';

import {TextBlock, Loader} from '../Components.js';
import TextField from '../Components/TextField.js';
import ErrorLabel from '../Components/ErrorLabel.js';
import ErrorFocus from '../Components/ErrorFocus.js';
import * as bookingsForm from './BookingsForm.js';
import './Bookings.scss';

class Bookings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availabilitiesLoading: true,
      availabilities: [],
      hasSubmit: false,
      hasPaid: false,
      savedBooking: {}
    };
  }

  async componentWillMount() {
    var availabilities = await Data.getAvailabilities()
      
    this.setState({
      availabilitiesLoading: false,
      availabilities: availabilities
    })
  }

  createOrder (data, actions) {
    console.log(['createOrder', data, actions]);
    return actions.order.create({
      purchase_units: [{
        amount: {
          value: '100'
        }
      }]
    });
  }

  onApprove (data, actions) {
    console.log(['onApprove', data, actions]);
    return actions.order.capture().then((details) => {
      console.log(['actions.order.capture().then()', details]);
      
      Data.submitPayment({
        bookingId: this.state.savedBooking.bookingId,
        payerEmail: details.payer.email_address,
        payerId: details.payer.payer_id,
        payerName: details.payer.name.given_name + details.payer.name.surname,
        amount: details.purchase_units[0].amount.value,
        status: details.status
      });

      this.setState({
        hasPaid: true
      });
    })
  }

  render () {
    if (!this.props.scriptsLoadedSuccessfully) {
      return <Loader />;
    }

    if (this.state.hasSubmit && !this.state.hasPaid) {
      return <BookingPay 
        onApprove={(data, actions) => this.onApprove(data, actions)}
        createOrder={(data, actions) => this.createOrder(data, actions)} />
    }

    if (this.state.hasPaid && this.state.hasSubmit) {
      return <BookingComplete />
    }

    return (
      <>
        <TextBlock heading="Booking">
          <p></p>
          <p>
            Please complete the form below to make your booking. If you have not already, please choose the dates you would like to book for your holiday.
          </p>
        </TextBlock>
        <Formik
          initialValues={bookingsForm.initialValues}
          validationSchema={bookingsForm.getValidationSchema()}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            var result = await Data.submitBooking(values);
            values.bookingId = result.bookingId;
            setSubmitting(false);
            this.setState({
              hasSubmit: true,
              savedBooking: values
            })
          }}
        >
          {({
            errors,
            handleChange,
            values,
            handleBlur,
            isSubmitting,
            setFieldValue
            }) => (
            <Form className="booking-form">
              <div>{JSON.stringify(errors)}</div>
              <div className="form-field-group">
                <h3>Holiday details</h3>
                <input type="hidden" name="price" id="price" />
                <div className="form-group">
                  <label className="form-group-label">Date:</label>
                  <DateSelector {...this.props} onChange={(id, date, price) => {
                    setFieldValue('price', price);
                    setFieldValue('date', date);
                    setFieldValue('dateId', id);
                  }} />
                  <ErrorMessage name="date" component={ErrorLabel} />
                </div>
              </div>
              <div className="form-field-group">
                <h3>Your details:</h3>
                <TextField name="firstName" label="First name:" {...{handleChange, handleBlur}} />
                <TextField name="lastName" label="Last name:" {...{handleChange, handleBlur}} />
                <TextField name="email" type="email" label="Email address:" {...{handleChange, handleBlur}} />
                <TextField name="phone" type="phone" label="Phone number:" {...{handleChange, handleBlur}} />
              </div>
              <div className="form-field-group">
                <h3>Address:</h3>
                <TextField name="addressLineOne" type="text" label="Line one:" {...{handleChange, handleBlur}} />
                <TextField name="addressLineTwo" type="text" label="Line two:" {...{handleChange, handleBlur}} />
                <TextField name="addressPostCode" type="text" label="Postcode:" {...{handleChange, handleBlur}} />
              </div>
              <div className="form-field-group">
                <h3>Your stay:</h3>
                <TextField name="numberOfAdults" type="number" value={values.numberOfAdults} label="Number of adults:" {...{handleChange, handleBlur}} />
                <TextField name="numberOfChildren" type="number" value={values.numberOfChildren} label="Number of children:" {...{handleChange, handleBlur}} />
                <TextField name="numberOfPets" type="number" value={values.numberOfPets} label="Number of pets:" {...{handleChange, handleBlur}} />
                <TextField name="petDescription" type="petDescription" label="Breed of pets:" {...{handleChange, handleBlur}} />
              </div>
              <div className="form-field-group">
                <h3 style={{marginBottom: 0}}>Required Bedrooms:</h3>
                <p style={{marginTop: '2px'}}>(To know what linen is required)</p>
                <div style={{'marginBottom': '5px'}}>
                  <input style={{'marginRight': '5px'}} type="checkbox" name="bedroomMaster" id="bedroomMaster" onChange={handleChange} onBlur={handleBlur} />
                  <label htmlFor="bedroomMaster">Master bedroom</label>
                </div>
                <div style={{'marginBottom': '5px'}}>
                  <input style={{'marginRight': '5px'}} type="checkbox" name="bedroomDouble" id="bedroomDouble" onChange={handleChange} onBlur={handleBlur} />
                  <label htmlFor="bedroomDouble">Small double bedroom</label>
                </div>
                <div style={{'marginBottom': '5px'}}>
                  <input style={{'marginRight': '5px'}} type="checkbox" name="bedroomTwin" id="bedroomTwin" onChange={handleChange} onBlur={handleBlur} />
                  <label htmlFor="bedroomTwin">Twin bedroom</label>
                </div>
                <div style={{'marginBottom': '5px'}}>
                  <input style={{'marginRight': '5px'}} type="checkbox" name="bedroomSofa" id="bedroomSofa" onChange={handleChange} onBlur={handleBlur} />
                  <label htmlFor="bedroomSofa">Sofa bed</label>
                </div>
              </div>
              <div className="form-group">
                <h3>Terms &amp; Conditions</h3>
                <input type="checkbox" name="acceptedTerms" id="acceptedTerms" onChange={handleChange} onBlur={handleBlur} />
                <label htmlFor="acceptedTerms">I have read and agree to the <Link to="/terms" target="_blank">Terms and Conditions</Link> of booking</label>
                <ErrorMessage name="acceptedTerms" component={ErrorLabel} />
              </div>
              <div className="form-group">
                <ReCAPTCHA
                  sitekey="6LfPeKcUAAAAAJ4nDz2BG2FAJ2SXnBHbNZqgx0vd"
                  onChange={value => setFieldValue('recaptchaResponse', value)} />
                <ErrorMessage name="recaptchaResponse" component={ErrorLabel} />
              </div>
              <button 
                type="submit" 
                disabled={isSubmitting}>
                  {isSubmitting ? '...' : 'Submit'}
              </button>              
              <ErrorFocus />
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default ScriptLoader('https://www.paypal.com/sdk/js?currency=GBP&client-id=' + Data.paypalClientId())(ai.withAITracking(Bookings));

const DateSelector = props => {
  var [dates, setDates] = useState(null);
  
  useEffect(() => {
    var fn = async () => {
      const result = await Data.getAvailabilities();
      setDates(result);
      var items = result.filter(x => x.id === props.match.params.id);

      if (items.length > 0) {
        props.onChange(
          items[0].id,
          items[0].text,
          parseInt(parsePrice(items[0].price)));
      }
    };

    fn();
    // eslint-disable-next-line
  }, [])

  var parsePrice = (text) => {
    return text.substring(1, text.length);
  };

  if (dates == null) {
    return <Loader />;
  };
  
  return (
    <select
      defaultValue={props.match.params.id}
      className="form-group-input"
      onChange={e => {
        const val = e.target.value;
        const selected = dates.filter(x => x.id === val);
        const priceValue = selected.length > 0
          ? parsePrice(selected[0].price)
          : '0';

        const dateValue = selected.length > 0
          ? selected[0].text
          : '';
          
        props.onChange(
            val,
            dateValue,
            parseInt(priceValue, 10));
      }}>
      {dates != null && dates.length > 0 && (
        <>
        <option key="-1" value="">Select</option>
        {dates.map((x, index) => (
          <option key={index} value={x.id}>{x.text} - {x.price}</option>
        ))}
        </>
      )}
    </select>
  );
}

const BookingPay = props => {
  const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

  return (
    <div className="pay">
      <TextBlock heading="Pay now to secure your booking">
        <p></p>
        <p className="pay-desc">
          To secure your booking, a <em>&pound;100</em> deposit is required. This payment can be securely made through PayPal now.
        </p>
        <p className="pay-desc">
          You will receive a confirmation email once you have paid your deposit. Feel free to contact us if you have any questions.
        </p>
        <div className="pay-button-container">
          <PayPalButton
            createOrder={(data, actions) => props.createOrder(data, actions)}
            onApprove={(data, actions) => props.onApprove(data, actions)} />
        </div>
      </TextBlock>
    </div>
  );
}

const BookingComplete = props => {
  const instaPosts = [
    'Bw5O7XcBVJM',
    'BxuzNvCgJ8D'
  ];

  return (
    <>
      <TextBlock heading="Booking Confirmed!">
        <p>
          Thank you for booking your holiday with Foxhole 5!
        </p>
        <p>
          You will receive an email with a link to your own, personal booking account where you can track payments and check and pay the remaining balance for your holiday.
        </p>
        <p>
          If you do not receive this email, please contact us.
        </p>
        <ul className="insta-embed-list">
          {instaPosts.map((x, index) => (
            <li key={index}>
              <InstagramEmbed
                url={`https://instagr.am/p/${x}`}
                containerTagName="div" />
            </li>
          ))}
        </ul>
      </TextBlock>
    </>
  );
}