import React from 'react';
import { Link } from "react-router-dom";

import './Sass/TextBlock.scss';
import './Sass/IconLink.scss';
import './Sass/Loader.scss';
import './Sass/Hero.scss';
import './Sass/PhotoImage.scss';

export function DetailList(props) {
  return <ul className="caravan-detail-list">{props.children}</ul>
}

export function DetailListItem(props) {
  return (
    <li>
      <div className="image-container"><img src={props.icon} alt={props.iconAlt} /></div>
      <span>{props.text}</span>
    </li>
  );
}

export function TextBlock(props) {
  return (
    <div className="text-block">
      <div className="text-block-heading">{props.heading}</div>
      {props.children && 
        <div className="text-block-content">
          {props.children}
        </div>
      }
    </div>
  );
}

export function Draggable(props) {
  let isDown = false;
  let mouseX = 0;
  let mouseY = 0;
  let currentTranslateX = 0;
  let currentTranslateY = 0;
  let ref = React.createRef();

  const mouseDown = (e) => {
    mouseX = e.pageX;
    mouseY = e.pageY;
    isDown = true;
  };

  const mouseUp = (e) => {
    isDown = false;
  };

  const mouseMove = (e) => {
    if (isDown) {
      var xOffset = e.pageX - mouseX;
      var yOffset = e.pageY - mouseY;
      
      mouseX = e.pageX;
      mouseY = e.pageY;
      currentTranslateX += xOffset;
      currentTranslateY += yOffset;

      var transform = `translate(${currentTranslateX}px, ${currentTranslateY}px)`;
      
      ref.current.style.transform = transform;
    }
  };

  return (
    <div ref={ref} onMouseMove={mouseMove} onMouseDown={mouseDown} onMouseUp={mouseUp}>
      {props.children}
    </div>
  );
}

export function IconGrid(props) {
  return (
    <div className="icon-grid">
      {props.children}
    </div>
  )
}

export function IconLink(props) {
  return (
    <Link to={props.to} className="icon-link">
      <img alt={props.text} src={props.image} />
      <span>{props.text}</span>
    </Link>
  )
}

export function Loader(props) {
  return (
    <div className="loader">
      <svg className="circular" viewBox="25 25 50 50">
        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="3" strokeMiterlimit="10"/>
      </svg>
    </div>
  )
}

export function Hero(props) {
  const heroStyle = {
    backgroundImage: `url(${props.backgroundImage})`
  }
  
  return (
    <div className="hero" style={heroStyle}>
      <h2>{props.text}</h2>
      <p>{props.strapLine}</p>
    </div>
  )
}

export function PhotoImage(props) {
  return (
    <div className="photo-image-container">
      <img src={props.src} alt={props.alt} />
    </div>
  );
}
