import React from 'react';
import { Link } from 'react-router-dom';
import './Sass/Header.scss';

const Header = props => {
  return (
    <header>
      <div className="container">
        <h1><Link to="/">Foxhole<span>5</span></Link></h1>
        <div id="nav-icon" className="opener" onClick={props.onMenuClick}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {props.children}
    </header>
  );
}
    
export default Header;