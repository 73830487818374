import * as yup from 'yup';

const initialValues = { 
  firstName: '', 
  lastName: '',
  email: '',
  phone: '',
  addressLineOne: '',
  addressLineTwo: '',
  addressPostCode: '',
  numberOfAdults: '0',
  numberOfChildren: '0',
  numberOfPets: '0',
  petDescription: '',
  price: 0,
  date: '',
  dateId: '',
  bedroomMaster: false,
  bedroomTwin: false,
  bedroomDouble: false,
  bedroomSofa: false,
  acceptedTerms: '',
  recaptchaResponse: ''
};

const requiredMessage = 'Please enter a value';
const tooShortMessage = 'Value too short';
const tooLargeMessage = 'Value too long';

const valName = () => {
  return yup
    .string()
    .required(requiredMessage)
    .min(2, tooShortMessage)
    .max(50, tooLargeMessage)
};

const valRequiredInteger = () => {
  return yup
    .number()
    .integer()
    .required(requiredMessage)
};

const valRequiredString = () => {
  return yup
    .string()
    .required(requiredMessage);
};

const getValidationSchema = () => {
  return yup.object().shape({
    firstName: valName(),
    lastName: valName(),
    email: yup.string().required(requiredMessage).email(),
    phone: yup
      .string()
      .required(requiredMessage)
      .min(5, tooShortMessage)
      .max(50, tooLargeMessage),
    addressLineOne: valRequiredString(),
    addressLineTwo: valRequiredString(),
    addressPostCode: valRequiredString(),
    numberOfAdults: valRequiredInteger(),
    numberOfChildren: valRequiredInteger(),
    numberOfPets: valRequiredInteger(),
    petDescription: yup
      .string(),
    date: yup
      .string()
      .required('Please select a date for your holiday'),
    dateId: yup.string(),
    price: yup.number().required('enter price'),
    acceptedTerms: yup
      .boolean()
      .test('terms-test', 'Please accept our terms and conditions', x => {
        return x === true;
      }),
    recaptchaResponse: yup
      .string()
      .default('')
      .required('Please complete recaptcha above')
  });
};

export { initialValues, getValidationSchema };