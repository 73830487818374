import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Helmet from 'react-helmet';

import './Sass/App.scss';
import Header from './Header.js';
import Menu from './Menu.js';
import Footer from './Footer.js';
import ScrollTop from './ScrollTop';
import Home from './Pages/Home.js';
import Caravan from './Pages/Caravan.js';
import Park from './Pages/Park.js';
import TermsAndConditions from './Pages/TermsAndConditions.js';
import Availability from './Pages/Availability.js';
import Contact from './Pages/Contact.js';
import Bookings from './Pages/Bookings.js';
import Payment from './Pages/Pay.js';
import Analytics from './Analytics.js';
import AboutYourStay from './Pages/AboutYourStay.js';

import { createBrowserHistory } from "history";
import { ai } from './TelemetryService';

const history = createBrowserHistory({ basename: '' });
ai.initialize({ history: history });

const BookingsWrapper = (props) => {
  return <article>
    <Bookings {...props} />
  </article>
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false
    }
  }

  handleMenuClick = event => {
    this.setState(state => ({
      menuOpen: !state.menuOpen
    }))
  }

  hideMenu = () => {
    this.setState({
      menuOpen: false
    })
  }

  render() {
    return (
      <Router>
        <Helmet titleTemplate="%s | Foxhole5.co.uk" />
        <ScrollTop>
          <div className={this.state.menuOpen? 'App open' : 'App closed'}>
            <Header menuOpen={this.state.menuOpen} onMenuClick={this.handleMenuClick}>
              <Menu onSelection={this.hideMenu} />
            </Header>
            <main>
              <Analytics id="UA-86266643-2" debug>
                <Route exact path="/" component={Home} />
                <Route path="/caravan" component={Caravan} />
                <Route path="/park" component={Park} />
                <Route path="/availability" component={Availability} />
                <Route path="/terms" component={TermsAndConditions} />
                <Route path="/about-your-stay" component={AboutYourStay} />
                <Route path="/contact" component={Contact} />
                <Route path="/booking/:id?" component={BookingsWrapper} />
                <Route path="/pay/:id" component={Payment} />
              </Analytics>
            </main>
            <Footer />
          </div>
        </ScrollTop>
      </Router>
    );
  }
}

export default App;
