import insideAll from './Mobile/inside-all.jpg';
import insideWideKitchen from './Mobile/inside-wide-kitchen.jpg';
import kitchen from './Mobile/kitchen.jpg';
import livingArea from './Mobile/living-area.jpg';
import outside from './Mobile/outside.jpg';
import outsideClose from './Mobile/outside-close.jpg';
import bedroomMaster from './Mobile/bedroom-master.jpeg';
import bedroomDouble from './Mobile/bedroom-double.jpeg';
import bedroomTwin from './Mobile/bedroom-twin.jpeg';

export default {
  insideAll: insideAll,
  insideWideKitchen: insideWideKitchen,
  kitchen: kitchen,
  livingArea: livingArea,
  outside: outside,
  outsideClose: outsideClose,
  bedroomMaster: bedroomMaster,
  bedroomDouble: bedroomDouble,
  bedroomTwin: bedroomTwin
};
