import React from 'react';
import { ai } from '../TelemetryService';
import './TermsAndConditions.scss';
import {TextBlock} from '../Components.js';

class TermsAndConditions extends React.Component {
  render() {
  return (
    <div className="terms-page">
    <TextBlock heading="Terms and Conditions">
      <p></p>
<p>
1. Please read these conditions carefully. In making a booking you warrant that you are 21 years of age or over and have the authority to accept and do accept on behalf of your party the terms and conditions set out below.
<h3>Terms</h3>
Our holidays have been designed with families and couples in mind. Therefore bookings by persons 21 years of age or under are not accepted unless they are a family booking. There may also be restrictions on bookings for ‘Single groups’ consisting of all male or female single people. However we may apply discretion in certain circumstances.
2. Your contract will be with Mrs J Herbert and Miss R Herbert the owners of the accommodation. The contract is subject to English law and the non-exclusive jurisdiction of courts within England and Wales. All terms are either per week (Friday to Friday/Saturday to Saturday and Monday to Monday), per short break (Monday to Friday/Friday to Monday) for the accommodation as equipped and described.
3. The usual check-in time is 4.00pm (subject to unavoidable delays). We ask that you vacate your accommodation by 10.00am on your day of departure. Guests arriving early are welcome to enjoy the Park facilities until their accommodation is ready. You are obliged to leave everything in a clean and tidy condition and you are responsible for any damage done or loss sustained during your stay.
4. Additional supplements may apply such as cots, high chairs etc. We guarantee that once you have made your booking and paid a deposit we will not increase your holiday price unless you make a change to your booking.
5. We reserve the right to alter prices in our brochure or on the website, which may go up or down. We will advise you of the current price at the time of booking. The terms and conditions for bookings may change from time to time. Please check at the time of booking.
<h3>The Holiday Contract</h3>
6. Making a Booking – Conditions of Booking
The person who books the holiday by messaging or email will be accepting the booking conditions on behalf of the holiday party. A contract between you and Fox Hole 5 will come into existence.
If you have booked via email or messaging you will receive email confirmation confirming your reservation, payment success and completion of your booking.
The contract binds you and all the members of your party. It is your responsibility to ensure that all members of your party accept the terms of the contract set out in these terms and conditions of booking. Failure to disclose all relevant information or comply with these terms may lead to termination of the contract and loss of the booking. We reserve the right to decline or terminate the booking of any guest(s) whose party make-up or behaviour interferes or may interfere with the general comfort of other guests. In this event no refunds will be made. Any decision made my Trevella Park will be upheld and supported by Fox Hole 5.
For May Half Term and the school summer holidays, bookings can only be accepted for a Saturday to Saturday for a minimum of 7 nights.
<h3>Use of your holiday home</h3>
Only those people listed on the booking can occupy your accommodation and use the facilities of the Park. If this legal requirement is not met, your booking will be terminated and you will be asked to leave, with no refund made.
As per government legislation it is illegal to smoke inside enclosed public buildings in England, Wales and Scotland. Outside smoking areas will be provided. Our accommodation is non-smoking but we cannot guarantee smoke free accommodation, or the holiday home to be allergy free.
</p>
<p>
<h3>Number in Your Party</h3>
7. The total number in your party must not exceed the capacity of the accommodation as advertised by us and as agreed on your booking form.
<h3>Payment</h3>
8. When you book you must pay the applicable deposit requested or you can pay in full to confirm your holiday booking.
Deposits will only be taken up to 4 weeks prior to the commencement of your holiday. Full balance payment for your holiday is due no later than 4 weeks before the start of your holiday. A balance reminder will be sent to you 6 weeks prior to payment due. If the balance is not received by the due date then your holiday will be treated as a cancellation. Bookings made within 4 weeks of the holiday start date must be paid in full at the time of booking.
<h3>Changes by You</h3>
9. Once a booking has been confirmed by us, should you require it to be amended or re-invoiced then, if we accept this change, an administration fee of £20.00 will be charged. You may transfer your booking to someone else/another party (introduced by you) at any time providing that you pay the administration fee and any outstanding balance.
<h3>Cancellation by You</h3>
10. Cancellations may be made up to 4 weeks before your arrival date. The deposit will be retained by Fox Hole 5 to cover administration costs. Cancellations made less than 4 weeks before your arrival date will be subject to consideration by Fox Hole 5 regarding the level of refund. This consideration will be based on the ability to re-sell the holiday period which Fox Hole 5 will endeavour to do. The deposit will be retained by Fox Hole 5 to cover administration costs.
<h3>Cancellation by Us</h3>
11. We always endeavour not to change the date or cancel your booking, but in exceptional circumstances this may be necessary. We will inform you of the change of date or cancellation as soon as possible and give you the following options: a. accept the alternative arrangements as notified to you b. choose another available break from us at the advertised price c. cancel your holiday with a full refund of any money you have paid. We do not pay compensation in circumstances where we have to cancel your booking including those arising from *force majeure. *Force majeure means circumstances beyond our control including (but not limited to) industrial disputes, natural disasters, fire, technical problems, bad weather and acts of government.
<h3>Website Accuracy</h3>
12. We have taken care to ensure the accuracy at the time of publication of  our website, however information and prices may have changed by the time you book. There may be small differences between the actual accommodation and the facilities and their description, as we are always looking for ways to make improvements. Whilst every care is taken to ensure that the details shown on the website are correct we cannot accept responsibility for errors contained therein or the results thereof.
No party shall be allowed to reproduce any of the contents of our website (including photographs) without written permission of Fox Hole 5 or any other copyright owner. Fox Hole 5 do not enter into any contract based on the content of this website.
<h3>Linen and Towels</h3>
13. In our accommodation, except for cots, bed linen is provided. This may be duvets or blankets and sheets. Towels will not be provided.
</p><p>
<h3>Entertainment, Activities and Facilities</h3>
14. Trevella Park reserve the right to alter or withdraw amenities or facilities or any activities without prior notice where reasonably necessary due to repairs, maintenance, weather conditions and circumstances beyond their control. Opening times may be limited outside the main holiday season. Certain facilities and activities may be subjected to an additional cost. Certain facilities may have age restrictions. Some facilities may be restricted to peak periods only. Payment for use of these facilities can be made at the Park Reception on request. In accordance with UK licensing laws.
<h3>Unreasonable Behaviour</h3>
15. For the convenience of our guests, we reserve the right to terminate the party's holiday without compensation or refund, where the unreasonable behaviour of the persons in the holiday party might impair the enjoyment, comfort or health of other guests, or the Trevella team members or staff. This may include but is not limited to verbal or physical abuse, and will be at Trevella’s management's discretion. Any decisions made by Trevella will be upheld and supported by Fox Hole 5.
<h3>Damage to the Accommodation</h3>
16. You are liable for any damage caused in the accommodation during the period of hire and may be charged for it. We retain the right to enter the accommodation (without prior notice if this is not practical or possible) if special circumstances or emergencies arise, for example if repairs need to be carried out. All guests are expected to treat our holiday accommodation and park facilities with care so that others may continue to enjoy them. Any accidental damage must be reported to Fox Hole 5 by email or messaging immediately, so that we can make the necessary repair or replacement. Accommodation will be inspected at the end of a stay.
<h3>Wheelchair/Disabled Persons</h3>
17. Regrettably our accommodation is accessed by steps and therefore would be impractical for wheelchair users.
<h3>Cots and High Chairs</h3>
18. These are available, on request at the time of booking, for a supplement of £5.00 per item, per break. Please note that you will need to bring your own cot linen.
<h3>Fishing</h3>
19. The fishing lakes at Trevella are stocked for coarse fishing, all you need to bring is a rod and a fishing license, which you can obtain from the post office or online, fishing permits can be obtained from park.
<h3>Pets</h3>
20. We welcome well-trained dogs in our accommodation but dogs that are specified in the Dangerous Dogs Act are not permitted in our accommodation or in the park. This includes all breeds of Pit Bulls, Japanese Tosa, Dogo Argentino, Fila Brasileiro even where these types of dog are muzzled. Additionally, Trevella reserve the right to refuse certain large breeds at their discretion, it is therefore important to fill out the breed of your dog/s on the booking form. Please contact Fox Hole 5 to double check that your breed of dog is accepted. We reserve the right to require the owner of any dog or pet considered disruptive or affecting the comfort of guests to remove it from the Park. Other pets may be permitted at our discretion. You must tell us that you are bringing a pet at the time of making your booking. You must bring your pet basket with you and ensure that your pet(s) do not lie on the bedding or chairs under any circumstances. Pets must not be left unattended in accommodation or elsewhere on the Park. They must be exercised on a lead and in the charge of an adult. You are responsible for cleaning up after your pet. Pets are not allowed in central areas including children play areas, shop or swimming pool areas.
</p><p>
<h3>Smoking</h3>
21. Smoking is not permitted in our accommodation. Smoking is not permitted in any of the public facilities on the park.
<h3>Your Vehicles</h3>
22. Your vehicles, their accessories and contents are left entirely at your own risk. We cannot accept responsibility for any loss or damage from or to any vehicle from any cause whatsoever. Speed limits in force on the Park must be followed for the safety of all the guests.
<h3>Local attractions</h3>
23. We accept no responsibility for information relating to local attractions, including details of distances, timings and activities. We provide this in good faith for information purposes only but make no recommendations in respect of any attraction, have not verified that the listed details are accurate or up-to-date and accept no responsibility for any reliance by you on them. You must make your own arrangements (including, where appropriate, having any necessary insurance in place) regarding the attractions with the operators and local tourist board information centres. Where we list attractions on our website we do not do so as an agent for the owner or operator of any attractions and so have no liability for any loss or damage suffered by you whether in contract or negligence as a result of any reliance on the information contained herein, or for any loss or damage suffered by you in visiting or purchasing any ticket for any such attraction.
<h3>Comments or Concerns</h3>
24. We will do everything possible to provide you with an acceptable standard of accommodation. However, if there is a problem please report it immediately to Wyatt’s Retreat by email or messaging so that remedial action, as appropriate, will be taken. We will use our best endeavours to resolve the problem as quickly as possible. If, at the end of your holiday, you feel that we have not dealt with your complaint satisfactorily, please write to us within 28 days of your return. You will then receive acknowledgement allowing us to investigate. We cannot accept any liability in relation to any shortcomings or claim of whatever nature if you fail to notify us of any complaint during your holiday allowing us the opportunity to resolve any issues to your satisfaction whilst you are with us.
<h3>Liability</h3>
25. We accept responsibility for those arrangements for your holiday that are within our control, but we cannot accept liability for any loss or damage suffered by you or any member of your party unless there was wilful default by us. Please note your personal belongings are your responsibility for any loss or damage.
<h3>Data Protection Policy</h3>
26. We will use the personal information that you provide to process your booking and provide you with your holiday and for internal statistical, market research and recording purposes. We will only correspond with the lead customer on the booking. It is your responsibility to inform all other members of your party what information about them you are providing to us, and what we will use it for. There are CCTV cameras at some locations at the holiday Park for crime prevention and safety reasons.
All bookings are subject to our Terms and Conditions and availability.
</p>
    </TextBlock>
    </div>
  );
  }
}

export default ai.withAITracking(TermsAndConditions);
