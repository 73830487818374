import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

class TelemetryService {
  constructor() {
    this.reactPlugin = new ReactPlugin();
  }

  initialize(reactPluginConfig) {
    let INSTRUMENTATION_KEY = 'b1b5eca1-2ff7-4d6e-afb7-ec6ef0f1805f';

    this.appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: INSTRUMENTATION_KEY,
            maxBatchInterval: 0,
            disableFetchTracking: false,
            extensions: [this.reactPlugin],
            extensionConfig: {
                [this.reactPlugin.identifier]: reactPluginConfig
            }
        }
    });

    this.appInsights.loadAppInsights();
  }

  withAITracking = function(component) {
    return withAITracking(this.reactPlugin, component);
  }
}

export let ai = new TelemetryService();