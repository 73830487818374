import React from 'react';
import { ai } from '../TelemetryService';
import Helmet from 'react-helmet';
import './Park.scss';

import {TextBlock, Hero, IconGrid, IconLink} from '../Components.js';
import Banners from '../Images/Banners/Banners.js';
import Icons from '../Images/Icons/Icons.js';

class Park extends React.Component {
  render() {
    return (<article>
        <Helmet>
          <title>The Park</title>
        </Helmet>
        <Hero 
          backgroundImage={Banners.thePark} 
          text="The Park" />
        <TextBlock heading="About the Park">
          <p>
            Trevella is a 5 star rated holiday park and receives regular glowing reviews from industry experts, the media, and most importantly - the guests!
          </p>
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe title="Park location" width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Trevella%20Crantock&t=&z=10&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
            </div>
          </div>
          <p>
            With a great selection of facilities for all guests to enjoy including; fishing lakes, outdoor swimming pool, kids play areas and more...
          </p>
          <p>
            Visit the official Trevella website to find out full details <a href="https://www.parkholidays.com/our-parks/cornwall/trevella/?utm_source=trevella.co.uk&utm_medium=referral">www.trevella.co.uk</a>
          </p>
        </TextBlock>
        <IconGrid>
          <IconLink to="/availability" text="Check Availability" image={Icons.caravan} />
        </IconGrid>
      </article>);
  }
 }
  
export default ai.withAITracking(Park);
