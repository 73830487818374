import icondirection from './src/001-direction.svg';
import iconcaravan from './src/002-caravan.svg';
import iconbed from './src/003-bed.svg';
import iconfamily from './src/004-family.svg';
import iconfire from './src/005-fire.svg';
import iconwindow from './src/006-window.svg';
import iconwifi from './src/007-wifi.svg';
import iconcutlery from './src/008-cutlery.svg';
import iconbedLinen from './src/009-bed-linen.svg';
import icontrack from './src/010-track.svg';
import iconfiveStars from './src/011-five-stars.svg';

export default {
  direction: icondirection,
  caravan: iconcaravan,
  bed: iconbed,
  family: iconfamily,
  fire: iconfire,
  window: iconwindow,
  wifi: iconwifi,
  cutlery: iconcutlery,
  bedLinen: iconbedLinen,
  track: icontrack,
  fiveStars: iconfiveStars
};
