import React from 'react';
import Helmet from 'react-helmet';
import { ai } from '../TelemetryService';

import './Caravan.scss';
import Icons from '../Images/Icons/Icons.js';
import Banners from '../Images/Banners/Banners.js';
import Photos from '../Images/Photos/Photos.js';
import {TextBlock, Hero, PhotoImage, IconGrid, IconLink} from '../Components.js';

import * as Components from '../Components.js';

class Caravan extends React.Component {
  render() {
    return (
    <article>
      <Helmet>
        <title>About the Caravan</title>
      </Helmet>
      <Hero
        backgroundImage={Banners.theVan}
        text="About the Caravan"
        strapLine="Our home away from home"/>
      <TextBlock heading="At a glance">
        <Components.DetailList>
          <Components.DetailListItem icon={Icons.caravan} text="12ft x 35ft Delta Pheonix" />
          <Components.DetailListItem icon={Icons.bed} text="3 Bedrooms" />
          <Components.DetailListItem icon={Icons.family} text="8 Berth" />
          <Components.DetailListItem icon={Icons.fire} text="Gas Central Heating" />
          <Components.DetailListItem icon={Icons.window} text="Double Glazing" />
          <Components.DetailListItem icon={Icons.wifi} text="Free Wi-Fi" />
          <Components.DetailListItem icon={Icons.cutlery} text="Self-Catering" />
          <Components.DetailListItem icon={Icons.bedLinen} text="Bed Linen" />
          <Components.DetailListItem icon={Icons.track} text="Dog Friendly" />
        </Components.DetailList>
      </TextBlock>
      <TextBlock heading="The details">
        <p>
          The modern, spacious, open plan living area of Fox Hole 5 is a home away from home. There are contemporary, black-out lined curtains at most windows (or blinds), with easy clean, wood effect flooring throughout. 
        </p>
        <h3>Kitchen</h3>
        <p>
          In the fully equipped kitchen (for 8 guests), there is a large fridge and freezer, microwave, toaster, kettle, and gas cooker. 
        </p>
        <PhotoImage src={Photos.insideWideKitchen} alt="Kitchen area" />
        <PhotoImage src={Photos.kitchen} alt="Kitchen" />
        <h3>Dining / Living</h3>
        <p>
          Leading through from the kitchen is the dining/living room. A cosy, corner dining area in one corner overlooking the living area with 2 large sofas (one being a pull-out double bed). A large, HD TV with Blu-Ray player is supplied to relax and watch a film or catch up with the soaps after a day at the beach. Board games, jigsaw puzzles, books and a selection of DVD’s are included for guests to enjoy.
        </p>
        <PhotoImage src={Photos.insideAll} alt="" />
        <h3>Bedrooms</h3>
        <p>
          The main double bedroom has a wardrobe, small dressing table with mirror, extra storage and 32inch TV.
        </p>
        <PhotoImage src={Photos.bedroomMaster} />
        <p>The second bedroom has a 3 quarter (small double) bed with storage cupboards.</p>
        <PhotoImage src={Photos.bedroomDouble} />
        <p>The third bedroom is a twin with wardrobe, mirror and extra storage cupboards.</p>
        <PhotoImage src={Photos.bedroomTwin} />
        <h3>Bathroom</h3>
        <p>
          There is a separate W/C with sink and a separate shower room with a large base shower with sink. Both have heated towel rails and mirrors.
        </p>
        <h3>Outside</h3>
        <p>
          There is a large decking area with outdoor furniture for al fresco dining. Or you can take in the lovely park surroundings and relax on the bench set. An outdoor tap and hose are accessible to wash down wetsuits/surfboards/dogs/or kids!
        </p>
        <PhotoImage src={Photos.outside} alt="Outside decking" />
        <PhotoImage src={Photos.outsideClose} alt="Outside dining area" />
      </TextBlock>
      <IconGrid>
        <IconLink to="/availability" text="Check Availability" image={Icons.caravan} />
      </IconGrid>
    </article>
  );
    }
}
  
export default ai.withAITracking(Caravan);
