import React from 'react';
import { ai } from '../TelemetryService';
import {TextBlock} from '../Components.js';

class Contact extends React.Component {
  render() {
    return (
      <TextBlock heading="Contact us">
        <p>Contact us if you would like more information or you would like to book a holiday at our caravan.</p>
        <ul className="dates-list">
          <li><a href="mailto:hellofoxhole5@gmail.com" rel="noopener noreferrer">hellofoxhole5@gmail.com</a></li>
          <li><a href="http://www.facebook.com/hellofoxhole5/" rel="noopener noreferrer">Facebook</a></li>
          <li><a href="https://www.instagram.com/hellofoxhole5/" rel="noopener noreferrer">Instagram</a></li>
        </ul>
      </TextBlock>
    )
  }
}
  
export default ai.withAITracking(Contact);
