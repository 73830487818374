import React from 'react';
import { NavLink } from "react-router-dom";
import './Sass/Menu.scss';

export default function(props) {
  return (
    <nav>
      <ul>
        <li><NavLink to="/" exact onClick={props.onSelection}>Welcome</NavLink></li>
        <li><NavLink to="/caravan" onClick={props.onSelection}>The Caravan</NavLink></li>
        <li><NavLink to="/park" onClick={props.onSelection}>The Park</NavLink></li>
        <li><NavLink to="/about-your-stay" onClick={props.onSelection}>About Your Stay</NavLink></li>
        <li><NavLink to="/availability" onClick={props.onSelection}>Availability</NavLink></li>
        <li><NavLink to="/booking" onClick={props.onSelection}>Booking</NavLink></li>
        <li><NavLink to="/contact" onClick={props.onSelection}>Contact</NavLink></li>
      </ul>
    </nav>
  )
};
