const services = {
  availabilities: '/api/GetEvents',
  bookings: '/api/SubmitBooking',
  payments: '/api/SubmitPayment',
  getBooking: '/api/GetBooking'
};

const generateUrl = function(relative) {
  return (isLocal()
    ? 'http://localhost:7071'
    : 'https://foxhole5functions.azurewebsites.net') + relative;
};

const isLocal = function() {
  return window.location.hostname.indexOf('localhost') > -1;
}

const postData = async (url = '', data = {}) => {
  // Default options are marked with *
    var response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: typeof(data) === 'string'
          ? data
          : JSON.stringify(data), // body data type must match "Content-Type" header
    });

    var text = await response.text();

    console.log([url, data, text]);

    return JSON.parse(text);
};

export default {
  paypalClientId: () => {
    return isLocal()
      ? 'AUd2F12-Cn8ckUp2bbFeb3YqEPCvm2Ozw8XdKFFAvhq7iUu5VQnLxf5yK9MaUwXlDYY8GGewVuPEfikS'
      : 'ATNVasACQj8_XBaS0fw0sIM9rZwrLpB1HfITRQBbxniWi5xZoG3MiqVldc6krKOi129MUlpT4p9ysy91';
  },

  getAvailabilities: async () => {
    var result = await fetch(generateUrl(services.availabilities));
    return await result.json();
  },

  submitBooking: async (data) => {
    const response = await postData(generateUrl(services.bookings), data);
    return response;
  },

  submitPayment: async (data) => {
    return await postData(generateUrl(services.payments), data);
  },

  getBooking: async (data) => {
    return await postData(generateUrl(services.getBooking), data);
  }
}
