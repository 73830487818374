import React from 'react';
import { ErrorMessage } from 'formik';
import ErrorLabel from './ErrorLabel.js';

class TextField extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      focused: false
    }
    
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }
  
  onFocus() {
    this.setState({focused: true})
  }
  
  onBlur(e) {
    this.setState({focused: false})
    this.props.handleBlur(e)
  }

  render() {
    var cssClass = 'form-group';
    if (this.state.focused){
      cssClass += ' focused';
    }
    
    return (
      <div className={cssClass}>
        <label className="form-group-label" htmlFor={this.props.name}>{this.props.label}</label>
        <input className="form-group-input" 
          type={this.props.type || 'text'}
          name={this.props.name} 
          value={this.props.value}
          id={this.props.name} 
          onFocus={this.onFocus} 
          onChange={this.props.handleChange} 
          onBlur={this.onBlur} />
        <ErrorMessage name={this.props.name} component={ErrorLabel} />
      </div>
    );
  }
}

export default TextField;
