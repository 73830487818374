import React from 'react';
import { ai } from '../TelemetryService';
import Helmet from 'react-helmet';

import {Hero, TextBlock, IconGrid, IconLink} from '../Components.js';

import Icons from '../Images/Icons/Icons.js';
import Banners from '../Images/Banners/Banners.js';

class Home extends React.Component {
  render() {
    return(
    <article>
      <Helmet>
        <title>Welcome to Foxhole 5, a privately owned caravan situated in Crantock, Cornwall at award winning Trevella Park</title>
      </Helmet>
      <Hero 
        backgroundImage={Banners.home} 
        text="Escape to Cornwall" 
        strapLine="Stay in a luxury privately owned caravan at an award winning holiday park" />
      <TextBlock heading="Welcome...">
        <p>
          Fox Hole 5 is a privately owned, luxury caravan based on the award winning Trevella Park.
        </p>
        <p>
          Take time to relax and enjoy the Cornish sea, sand, and food! 
          Our family and dog friendly, 3 bed, 8 berth, caravan is centrally 
          located at Crantock, Newquay, enabling you to visit north and 
          south Cornwall within 30-60 minutes each way!
        </p>
        <p>
          Crantock Beach is within walking distance of Fox Hole 5, 
          with Newquay just on the other side of the River Gannel. 
          This flows down the right hand side of the beach and at 
          low tide, reveals a bridge which gives you a short-cut to walk up 
          to Newquay and spend the day there!
        </p>
      </TextBlock>
      <IconGrid>
        <IconLink to="/caravan" text="Caravan Details" image={Icons.caravan} />
        <IconLink to="/park" text="Park Details" image={Icons.fiveStars} />
      </IconGrid>
    </article>);
  }
}
  
export default ai.withAITracking(Home);
