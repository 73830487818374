import React from 'react';
import { Link } from 'react-router-dom';
import { ai } from '../TelemetryService';

import { TextBlock, Loader } from '../Components.js';
import Service from '../Data.js';

import './Availability.scss';

class Availability extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [] 
    }
  }

  async componentWillMount() {
    var result = await Service.getAvailabilities()
    
    this.setState({
      loading: false,
      data: this.formatJson(result)
    })
  }

  formatJson(json) {
    var grouped = this.groupBy(json, 'yearOnly');

    let data = [];
    for(var key in grouped) {
      data.push({
        year: key,
        dates: grouped[key]
      });
    }
    
    return data;
  }

  groupBy (arr, prop) {
    return arr.reduce(function(groups, item) {
      const val = item[prop]
      groups[val] = groups[val] || []
      groups[val].push(item)
      return groups
    }, {})
  }

  wrap (content) {
    return (
      <article>
        <TextBlock heading="Availability">
          <p></p>
          <p>Below are the available dates and prices.</p>
          <p>Before you book, a £100 deposit will be required to secure your booking - this will need to be paid by PayPal after completing the booking form. The balance of your holiday will then be due 4 weeks before your arrival date.</p>
          <p>Either click on the dates you wish to book, or click the Book Now button at the bottom of the page.</p>
        </TextBlock>
        {content}
      </article>
    )
  }

  render () {
    if (this.state.loading) {
      return this.wrap(<Loader />);
    }

    const windowWidth = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth
    
    const isMobile = windowWidth < 720

    const itemText = (item) => isMobile
      ? item.shortText
      : item.text

    return this.wrap(
      <>
      {this.state.data.map((data, index) => (
        <section key={index}>
          <h2>Dates in {data.year}</h2>
          <ul className="dates-list">
            {data.dates.map((inner, i) => (
              <li key={i}>
                <Link to={`/booking/${inner.id}`}>{itemText(inner)}</Link><em>{inner.price}</em>
              </li>
            ))}
          </ul>
        </section>
      ))}
      
      {!this.state.loading && this.state.data.length === 0 && (
        <div style={{textAlign: 'center'}}>No dates available</div>
      )}

      {!this.state.loading && this.state.data.length > 0 && (
        <div style={{textAlign: 'center'}}>
          <Link className="contact-button" to="/booking">Book now</Link>
        </div>
      )}
      </>
    );
  }
}

export default ai.withAITracking(Availability);