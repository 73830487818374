import React from 'react';
import { TextBlock } from '../Components.js';

const AboutYourStay = props => {
    return (
        <article>
        <TextBlock heading="About Your Stay">
            <p>Thank you for considering Foxhole5 for your holiday!</p>
            <p>
                If you decide to book with us, please find some information you may find helpful:
            </p>
            <p>
                <strong>General Information:</strong>
                <ul>
                    <li>Bookings are 7 days - Saturday to Saturday.</li>
                    <li>Check In: 4pm on the day of your arrival.</li>
                    <li>Check Out: 10am on the day of your departure.</li>
                    <li>Keys: You will be sent a key safe code once you have paid your final balance, 4 weeks before your arrival date.</li>
                    <li>Supermarkets: There are many supermarkets around Newquay you can visit on the way to get your supplies for your holiday. Or you can even get an online shop organised to be delivered to Foxhole5 for after you've arrived!</li>
                </ul>
            </p>
            <p>
                <strong>What you will need during your stay:</strong>
                <ul>
                    <li>We provide bed linen, but no towels. Please bring your own bath towels, and beach towels.</li>
                    <li>Washing up equipment, food, condiments, tea/coffee, cleaning products.</li>
                    <li>Throws for the furniture - if you would like your pets to join you on the sofa. This helps keep the caravan in good condition for future guests to enjoy.</li>
                </ul>
                <strong>What to do before you leave:</strong>
                <ul>
                    <li>Make sure all washing up is done and no dirty pots left in the caravan.</li>
                    <li>Empty the bins and take to the bin at the end of Foxhole 5 or near the toilet blocks.</li>
                    <li>Check the caravan for any of your belongings - under beds, in cupboards.</li>
                </ul>
            </p>
        </TextBlock>
        </article>
    )
};

export default AboutYourStay;